export const RadioDataRecords = [
    {
        "id": "1",
        "language": "Sanskrit",
        "station": "Divyavani",
        "url": "https://divyavani.radioca.st/stream",
        "time": "IST"
    },
    {
        "id": "2",
        "language": "Assamese",
        "station": "AIR Assamese",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio133/playlist.m3u8",
        "time": "IST"
    },
    {
        "id": "3",
        "language": "Assamese",
        "station": "AIR Guwahati",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio237/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "4",
        "language": "Assamese",
        "station": "AIR Silchar",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio090/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "5",
        "language": "Bengali",
        "station": "AIR Agartala",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio130/playlist.m3u8",
        "time": "IST"
    },
    {
        "id": "6",
        "language": "Bengali",
        "station": "AIR Bangla",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio134/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "7",
        "language": "Bengali",
        "station": "AIR Silguri",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio164/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "8",
        "language": "Gujarati",
        "station": "AIR Daman",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio208/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "9",
        "language": "Gujarati",
        "station": "AIR Gujarati",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio135/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "10",
        "language": "Gujarati",
        "station": "AIR VBS Rajkot",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio156/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "11",
        "language": "Hindi",
        "station": "AIR Agra",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio059/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "12",
        "language": "Hindi",
        "station": "AIR Kanpur",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio194/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "13",
        "language": "Hindi",
        "station": "AIR Varanasi",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio094/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "14",
        "language": "Hindi",
        "station": "AIR VBS Indore",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio241/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "15",
        "language": "Hindi",
        "station": "Bombay Beats",
        "url": "https://strmreg.1.fm/bombaybeats_mobile_mp3",
        "time": "IST"
    },
    {
        "id": "16",
        "language": "Hindi",
        "station": "Masala Radio (Houston)",
        "url": "https://s3.voscast.com:10201/stream.mp3",
        "time": "PST"
    },
    {
        "id": "17",
        "language": "Kannada",
        "station": "AIR Dharwad",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio150/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "18",
        "language": "Kannada",
        "station": "AIR Hassan",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio172/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "19",
        "language": "Kannada",
        "station": "AIR Mangalore",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio073/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "20",
        "language": "Kannada",
        "station": "AIR Mysuru",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio177/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "21",
        "language": "Kashmiri",
        "station": "AIR Kathua",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio190/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "22",
        "language": "Khasi",
        "station": "AIR Shillong",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio161/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "23",
        "language": "Konkani",
        "station": "AIR Panaji",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio049/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "24",
        "language": "Malayalam",
        "station": "AIR Kochi",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio045/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "25",
        "language": "Malayalam",
        "station": "AIR Kozhikode",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio083/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "26",
        "language": "Manipuri",
        "station": "AIR Imphal Kangla",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio209/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "27",
        "language": "Marathi",
        "station": "AIR Ahmednagar",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio019/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "28",
        "language": "Marathi",
        "station": "AIR Chandrapur",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio074/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "29",
        "language": "Marathi",
        "station": "AIR Kolhapur",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio063/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "30",
        "language": "Marathi",
        "station": "AIR Pune FM",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio013/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "31",
        "language": "Marathi",
        "station": "AIR Satara",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio076/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "32",
        "language": "Mizo",
        "station": "AIR Aizawal",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio221/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "33",
        "language": "Odia",
        "station": "AIR Rourkela",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio215/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "34",
        "language": "Odia",
        "station": "AIR Sambalpur",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio181/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "35",
        "language": "Punjabi",
        "station": "AIR Chandigarh",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio111/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "36",
        "language": "Punjabi",
        "station": "AIR Punjabi",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio138/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "37",
        "language": "Rajasthani",
        "station": "AIR Kota",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio084/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "38",
        "language": "Rajasthani",
        "station": "AIR FM Udaipur",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio149/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "39",
        "language": "Tamil",
        "station": "AIR Chennai FM Gold",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio021/playlist.m3u8",
        "time": "IST"
    },
    {
        "id": "40",
        "language": "Tamil",
        "station": "AIR Kodaikanal",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio051/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "41",
        "language": "Tamil",
        "station": "AIR Ooty",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio207/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "42",
        "language": "Tamil",
        "station": "AIR Tamil",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio025/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "43",
        "language": "Tamil",
        "station": "AIR Tutikorin",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio121/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "44",
        "language": "Telugu",
        "station": "AIR Hyderabad VBS",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio034/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "45",
        "language": "Telugu",
        "station": "AIR Tirupati",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio144/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "46",
        "language": "Telugu",
        "station": "AIR VBS Vijayawada",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio176/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "47",
        "language": "Telugu",
        "station": "AIR Vijayawada",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio175/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "48",
        "language": "Telugu",
        "station": "AIR Visakhapatnam",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio080/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "49",
        "language": "Telugu",
        "station": "AIR Warangal",
        "url": "https://air.pc.cdn.bitgravity.com/air/live/pbaudio154/chunklist.m3u8",
        "time": "IST"
    },
    {
        "id": "50",
        "language": "Telugu",
        "station": "Induvadana",
        "url": "https://n06.radiojar.com/khx8y1z2kwzuv",
        "time": "GMT"
    },
    {
        "id": "51",
        "language": "Telugu",
        "station": "London Telugu Radio (UK)",
        "url": "https://c8.radioboss.fm:18033/stream",
        "time": "GMT"
    },
    {
        "id": "52",
        "language": "Telugu",
        "station": "Radio Surabhi (Texas)",
        "url": "https://radiosurabhi.streamguys1.com/live1-web",
        "time": "PST"
    },
    {
        "id": "53",
        "language": "Telugu",
        "station": "Swara Madhuri",
        "url": "https://stream-155.zeno.fm/zman3kqfzeruv",
        "time": "GMT"
    },
    {
        "id": "54",
        "language": "Telugu",
        "station": "Telugu One Radio (TORi)",
        "url": "https://proxy.amprad.io/listen/aHR0cDovLzE3My4yMDMuMTMzLjE4Nzo5NzAwLzs=",
        "time": "EST"
    },
    {
        "id": "55",
        "language": "Telugu",
        "station": "Virijallu Telugu Radio (USA)",
        "url": "https://26383.live.streamtheworld.com/SAM02AAC10_SC",
        "time": "PST"
    },
    {
        "id": "56",
        "language": "Urdu",
        "station": "AIR Urdu",
        "url": "https://airhlspush.pc.cdn.bitgravity.com/httppush/hlspbaudio231/hlspbaudio23164kbps.m3u8",
        "time": "IST"
    },
    {
        "id": "57",
        "language": "English",
        "station": "Al Jazeera English (Qatar) (Gov)",
        "url": "https://live-hls-audio-web-aje.getaj.net/VOICE-AJE/01.m3u8",
        "time": "AST"
    },
    {
        "id": "58",
        "language": "English-US",
        "station": "NPR 24 Hour Program",
        "url": "https://npr-ice.streamguys1.com/live.mp3",
        "time": "EST"
    },
    {
        "id": "59",
        "language": "English-US",
        "station": "Voice of America (Gov)",
        "url": "http://voa-28.akacast.akamaistream.net/7/54/322040/v1/ibb.akacast.akamaistream.net/voa-28",
        "time": "EST"
    },
    {
        "id": "60",
        "language": "English-UK",
        "station": "BBC World Service",
        "url": "https://stream.live.vc.bbcmedia.co.uk/bbc_world_service",
        "time": "GMT"
    },
    {
        "id": "61",
        "language": "English-UK",
        "station": "Times Radio",
        "url": "https://timesradio.wireless.radio/stream?aw_0_1st.platform=website&aw_0_1st.playerid=wireless-website",
        "time": "GMT"
    },
    {
        "id": "62",
        "language": "English-US",
        "station": "BIGGS Radio Chicago",
        "url": "https://c22.radioboss.fm:18298/stream",
        "time": "CST"
    },
    {
        "id": "63",
        "language": "English-US",
        "station": "Washington's Top News (WTOP)",
        "url": "https://15313.live.streamtheworld.com/WTOPFM.mp3?dist=hubbard&source=hubbard-web&ttag=web&gdpr=0",
        "time": "EST"
    },
    {
        "id": "64",
        "language": "French",
        "station": "France Culture",
        "url": "http://direct.franceculture.fr/live/franceculture-midfi.mp3",
        "time": "CET"
    },
    {
        "id": "65",
        "language": "French",
        "station": "France Info",
        "url": "https://stream.radiofrance.fr/franceinfo/franceinfo_hifi.m3u8",
        "time": "CET"
    },
    {
        "id": "66",
        "language": "French",
        "station": "France Inter",
        "url": "http://direct.franceinter.fr/live/franceinter-midfi.mp3",
        "time": "CET"
    },
    {
        "id": "67",
        "language": "French",
        "station": "France Music",
        "url": "http://direct.francemusique.fr/live/francemusique-midfi.mp3",
        "time": "CET"
    }
];